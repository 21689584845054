import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { AppActions } from 'app/state';

import { DatasheetApiActions } from '.';

@Injectable({
    providedIn: 'root',
})
export class DatasheetThrobberEffects {
    private readonly actions$ = inject(Actions);
    public deleteDatasheetShowThrobber$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatasheetApiActions.deleteDatasheet),
            map(() => {
                return AppActions.showThrobber({
                    selectorClass: 'templates-overview',
                    loadingMessage: 'APP.TEMPLATES_OVERVIEW.LOADING',
                });
            }),
        ),
    );

    public deleteDatasheetClearThrobber$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatasheetApiActions.deleteDatasheetSuccess),
            map(() => {
                return AppActions.clearThrobber({ selectorClass: 'templates-overview' });
            }),
        ),
    );
}
